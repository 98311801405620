<template>
  <v-btn :disabled="loading || !selectedBudgets.length" color="success" depressed size="small" @click="getPDF()"
    :loading="loading">
    <v-icon left>mdi-printer</v-icon> Rel. Orçamento + Rel. Prorrogação
  </v-btn>
</template>

<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png";
import ona from "../../../assets/ONA_Nivel_2.png";
import UtilsPDF from '../../../service/utilsPDF';

const { withCRUDUtils } = UtilsFunc
export default {
  name: "OrcamentoProrrogacao",
  data: () => withCRUDUtils({
    prorrogacao: [],
    orcamento: [],
    quadroClinico: [],
    pacote: [],
    taxa: [],
    equipamentos: [],
    loading: false,
    totalFee: null,
    totalEquipment: null,
    planoTerapeutico: false,
  }),
  props: {
    selectedBudgets: {
      type: Array,
      default: () => []
    },
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProrrogacao(),
          this.getPacote(),
          this.getTaxa(),
          this.getEquipamentos()
        ]);
        this.generateUnifiedPDF()
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (getPDF):", error);
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    async generateUnifiedPDF() {
      const doc = new jsPDF({ compress: true });
      this.generateBudgetPDF(doc);
      this.generateProrrogacaoPDF(doc);

      UtilsPDF.addPageNumbers(doc);

      const orcamento = this.orcamento;
      const data = this.orcamento[0]

      const data_inicio = data?.data_inicio
      const data_fim = data?.data_fim

      const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';

      const empresa = orcamento[0]?.convenio;
      const name = orcamento[0]?.paciente;

      const filename = `Orçamento_e_Relatório_Prorrogação_${name}_${empresa}_Período_${period}.pdf`;

      const pdfBuffer = doc.output('arraybuffer')
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" })
      const formData = new FormData()
      formData.append("file", pdfBlob, filename)
      formData.append("filename", filename);

      try {
        const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)
        const signedPdfUrl = signedForm.path
        this.download(signedPdfUrl, filename)
        this.$toast.success('O download do relatório foi realizado com sucesso!');
      } catch (error) {
        this.$toast.error("Error signing PDF (generateUnifiedPDF):", error.response ? error.response.data : error.message);
      }
    },
    drawHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Error signing PDF (drawHeader):", error);
      }

      const data = this.orcamento[0]

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45
      let yPosition = 45

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      // doc.roundedRect(x, y, largura, altura, border-radius, border-radius, 'preenchimento');

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Orçamento nº ${data?.id}`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28);
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);


      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}` : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });


      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (doc.internal.getNumberOfPages() === 1) {
        yPosition = 75;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text('Segue o orçamento detalhado conforme solicitação.', 8, yPosition);

        yPosition = 82;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text('Ficamos no aguardo da autorização para darmos início aos serviços abaixo.', 8, yPosition);
      }
    },
    async generateBudgetPDF(doc) {
      this.drawHeader(doc);

      const pageWidth = 210;
      const maxHeight = 280;
      const lineHeight = 6;

      const data = this.orcamento[0]
      const startDate = new Date(data?.data_inicio);
      const endDate = new Date(data?.data_fim);
      const numberOfDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      const prorrogacao = this.prorrogacao

      let yPosition = 93;
      this.contentAdded = false;

      let xPosition = 10

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Resumo do Orçamento");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Resumo do Orçamento", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");

      yPosition += lineHeight * 2;
      yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);

      const packageData = this.pacote.length > 0 ? this.pacote[0] : null;

      if (packageData) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Pacote(s)", xPosition, yPosition);
        yPosition += lineHeight;
        const nomePacote = (packageData?.pacote.nomecomercial && packageData?.pacote.nomecomercial.trim() !== "") ? `${packageData.pacote?.nome} - (${packageData?.pacote.nomecomercial})` : packageData.pacote?.nome;
        const row = {
          'Código': packageData.pacote?.codigo || '',
          'Pacote': nomePacote,
          'Qtd.': packageData.quantidade_pacote || '',
          'Valor Unitário': `${UtilsPDF.formatToBRL(packageData.valor_cobranca_pacote)}`,
          'Des %.': packageData.desconto_pacote || '--',
          'Acres %.': packageData.acrescimo_pacote || '--',
          'Valor Total': `${UtilsPDF.formatToBRL(packageData.valor_cobranca_final_pacote)}`
        };

        const columns = ['Código', 'Pacote', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
        const rows = [Object.values(row)];

        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
          margin: { left: 8, right: 8 }
        });

        const tableHeight = doc.lastAutoTable.finalY;
        yPosition = tableHeight + lineHeight;

        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Pacote(s): ${UtilsPDF.formatToBRL(packageData.valor_cobranca_final_pacote)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // this.$toast.error("Nenhum dado de pacote encontrado (L-279).");
      }

      doc.setFontSize(14);
      doc.setFont("helvetica", "bold");
      doc.text("Procedimento(s)", xPosition, yPosition);

      yPosition += lineHeight * 2;
      yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);

      const table = prorrogacao.map(item => {
        const discount = item.desconto ? parseFloat(item.desconto) / 100 : 0;
        const addition = item.acrescimo ? parseFloat(item.acrescimo) / 100 : 0;
        const adjustedUnitValue = (item.cobranca !== '0' && item.valor_cobranca)
          ? parseFloat(item.valor_cobranca) * (1 + addition - discount)
          : 0;

        const qtdRealizada = item.especial === true ? parseInt(item.qtdEspecial) : item.qtd;
        const valorTotal = adjustedUnitValue * qtdRealizada;
        const nomeProcedimento = (item?.nomecomercial && item.nomecomercial.trim() !== "") ? `${item.procedimento} - (${item.nomecomercial})` : item.procedimento;

        return {
          'Código': item.codigo,
          'Procedimento': nomeProcedimento,
          'Frequência': item.especial === true ? '' : item.frequencia,
          'Qtd.': qtdRealizada,
          'Valor Unitário': UtilsPDF.formatToBRL(parseFloat(item.valor_cobranca) || 0),
          'Des %.': item.cobranca === '0' ? '100.0' : (item.desconto || '--'),
          'Acres %.': item.acrescimo || '--',
          'Valor Total': UtilsPDF.formatToBRL2(valorTotal),
        }
      });
      const columns = ['Código', 'Procedimento', 'Frequência', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
      const rows = []
      table.forEach(item => {
        const row = []
        columns.forEach(column => {
          row.push(item[column])
        })
        rows.push(row)
      })

      doc.autoTable({
        startY: yPosition,
        head: [columns],
        body: rows,
        styles: { lineWidth: 0.1 },
        margin: { left: 8, right: 8 },
        columnStyles: {
          2: { cellWidth: 30 },
          7: { cellWidth: 28 }
        },
      });

      yPosition = doc.lastAutoTable.finalY + lineHeight;

      // Cálculo do Valor Total dos Procedimentos
      const totalBudget = table.reduce((acc, item) => {
        const valorTotal = UtilsPDF.parseFormattedCurrency(item['Valor Total']) || 0;
        return acc + valorTotal;
      }, 0);

      yPosition += lineHeight;
      doc.setFontSize(10);
      doc.setFont("helvetica", "bold");
      doc.text(`Valor Total do(s) Procedimento(s): ${totalBudget ? UtilsPDF.formatToBRL2(totalBudget) : 'R$ 0,00'}`, 200, yPosition, { align: "right" });

      yPosition += lineHeight * 2;
      yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);

      const taxas = this.taxa;
      if (taxas && taxas.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Taxa(s)", xPosition, yPosition);

        yPosition += lineHeight;
        let totalFee = 0;

        const columns = [
          'Código',
          'Taxa',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        taxas.forEach(taxa => {
          if (taxa.ativo) {
            const valorCobrancaTaxa = taxa?.valor_cobranca_taxa || 0;
            const valorCobrancaFinalTaxa = taxa?.valor_cobranca_final_taxa || 0;
            const nomeTaxa = (taxa?.taxa?.nomecomercial && taxa.taxa.nomecomercial.trim() !== "") ? `${taxa?.taxa?.taxa?.nome} - (${taxa?.taxa?.nomecomercial})` : taxa?.taxa?.taxa?.nome;

            const row = {
              'Código': taxa?.taxa?.codigo || '',
              'Taxa': nomeTaxa || '',
              'Qtd.': String(taxa?.quantidade_taxa || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaTaxa),
              'Des %.': taxa?.desconto_taxa || '--',
              'Acres %.': taxa?.acrescimo_taxa || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalTaxa)
            };

            rows.push(Object.values(row));

            this.totalFee += valorCobrancaFinalTaxa;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-399):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(totalFee)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, 250);

      const equipamentos = this.equipamentos;
      if (equipamentos && equipamentos.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");

        doc.text("Equipamento(s)", xPosition, yPosition);

        yPosition += lineHeight;
        let totalEquipment = 0;

        const columns = [
          'Código',
          'Equipamento',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        equipamentos.forEach(equipamento => {
          if (equipamento.ativo) {
            const valorCobranca = equipamento?.valor_cobranca_equipamento || 0;
            const valorCobrancaFinal = equipamento?.valor_cobranca_final_equipamento || 0;
            const nomeEquipamento = (equipamento?.equipamento?.nomecomercial && equipamento.equipamento.nomecomercial.trim() !== "") ? `${equipamento?.equipamento?.equipamento?.nome} - (${equipamento?.equipamento?.nomecomercial})` : equipamento?.equipamento?.equipamento?.nome;

            const row = {
              'Código': equipamento?.equipamento?.codigo || '',
              'Equipamento': nomeEquipamento || '',
              'Qtd.': String(equipamento?.quantidade_equipamento || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobranca),
              'Des %.': equipamento?.desconto_equipamento || '--',
              'Acres %.': equipamento?.acrescimo_equipamento || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinal)
            };

            rows.push(Object.values(row));

            this.totalEquipment += valorCobrancaFinal;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (generateBudgetPDF):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Equipamento(s): ${UtilsPDF.formatToBRL2(totalEquipment)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;

        if (yPosition > 240) {
          doc.addPage();
          this.drawHeader(doc);
        }
      } else {
        // console.log('Nenhum dado de equipamento encontrado');
      }

      const pacoteValor = this.pacote != '' ? this.pacote[0] : 0;
      const totalOrcamento =
        totalBudget + (
          pacoteValor?.valor_cobranca_final_pacote ?
            pacoteValor?.valor_cobranca_final_pacote :
            0
        ) + this.totalFee + this.totalEquipment

      const dailyAverage = totalOrcamento / numberOfDays;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 260, 80, this.drawHeader);

      const text = 'do Orçamento'
      UtilsPDF.summary(doc, yPosition, lineHeight, dailyAverage, totalOrcamento, text)

      yPosition += lineHeight + 2;
      yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);

      const operadora = data && data.convenio ? data.convenio : '';
      UtilsPDF.addSignatureBlock(doc, operadora, lineHeight, xPosition, 255);

      doc.addPage()
      yPosition = this.checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight);

      if (!this.contentAdded) {
        this.drawHeader(doc);
        this.contentAdded = true; // Marque que o cabeçalho foi renderizado
      }
    },
    drawProrrogacaoHeader(doc) {
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.error("Falha ao adicionar a imagem ao PDF (drawProrrogacaoHeader).", error);
      }

      const prorrogacoes = this.prorrogacao;
      const data = this.orcamento[0];
      const quadro = this.quadroClinico;

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45;
      let yPosition = 45;

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");
      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Relatório para Prorrogação`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      doc.text("Data e Hora da Impressão:", 200, 32, { align: "right" });
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, 200, 36, { align: "right", });

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${UtilsPDF.formatDate(data.data_inicio)} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 36);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight

      const dataNascimento = data && data.data_nascimento
        ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}`
        : 'Data Nascimento: ';

      doc.text(
        dataNascimento,
        200,
        initialYPosition,
        { align: "right" }
      );

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxHeight = 270;
      const pageWidth = 210;
      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (!this.planoTerapeutico) {

        doc.setFontSize(12);
        doc.text(`Solicito prorrogação dos atendimentos para o período de ${UtilsPDF.formatDate(data.data_inicio)} até ${UtilsPDF.formatDate(data.data_fim)} para o(a) `, 20, 73);

        yPosition += lineHeight
        doc.setFontSize(12);
        doc.text(`paciente ${data.paciente}, Data de Nascimento ${UtilsPDF.formatDate(data.data_nascimento)}. Segue abaixo o plano`, 10, 79);

        yPosition += lineHeight;
        doc.setFontSize(12);
        doc.text(`terapêutico proposto e os relatórios de cada especialidade que atende o(a) paciente.`, 10, 85);
        yPosition += lineHeight * 2;

        // Parte do Quadro Clínico se houver
        if (quadro?.quadro_clinico) {
          yPosition += lineHeight * 2;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

          doc.setFont("helvetica", "bold");
          let titulo = "Quadro Clínico: ";
          let tituloLargura = doc.getTextWidth(titulo);
          doc.text(titulo, xFixedPosition, yPosition);
          yPosition += lineHeight;
          doc.setFont("helvetica", "normal");
          let maxLineWidth = 210;
          let conteudo = quadro.quadro_clinico;
          let linhas = doc.splitTextToSize(conteudo, maxLineWidth - tituloLargura);
          doc.text(linhas, xFixedPosition, yPosition);
          yPosition += (linhas.length * 5);
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        }

        // Adiciona o título "Plano Terapêutico Proposto"
        yPosition += ((lineHeight * 2) + 5);
        doc.setFontSize(16);
        doc.setFont("helvetica", "bold");
        let textWidth2 = doc.getTextWidth("Plano Terapêutico Proposto");
        let xCenteredPosition2 = pageWidth / 2 - textWidth2 / 2;
        doc.text("Plano Terapêutico Proposto", xCenteredPosition2, yPosition);

        yPosition += lineHeight * 2;
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");

        // Filtrar prorrogacoes para remover itens duplicados com base no procedimento e frequencia
        let uniqueItems = [];
        let uniqueCheckSet = new Set();

        prorrogacoes.forEach(item => {
          let uniqueKey = `${item.procedimento}-${item.frequencia}`;
          if (!uniqueCheckSet.has(uniqueKey)) {
            uniqueCheckSet.add(uniqueKey);
            uniqueItems.push(item);
          }
        });

        const table = uniqueItems.map(item => ({
          'Procedimento': item.procedimento,
          'Frequência': item.frequencia,
        }));
        const columns = ['Procedimento', 'Frequência'];
        const rows = [];
        table.forEach(item => {
          const row = [];
          columns.forEach(column => {
            row.push(item[column]);
          });
          rows.push(row);
        });

        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
        });

        yPosition = doc.lastAutoTable.finalY + lineHeight; // Obtém a altura da tabela
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, 250);
        this.planoTerapeutico = true;
      }

      yPosition += lineHeight;
      this.position = yPosition;
      // Exclui uma página em branco no fim do arquivo, se houver
      if (!this.contentAdded && doc.internal.getNumberOfPages() > 1) {
        doc.deletePage(doc.internal.getNumberOfPages());
      }
    },
    async generateProrrogacaoPDF(doc) {
      this.drawProrrogacaoHeader(doc);

      const maxWidth = 185;
      const maxHeight = 280;
      const pageWidth = 210;
      const xFixedPosition = 10;
      const lineHeight = 6;
      const lineWidth = 3;

      const data = this.prorrogacao;

      this.contentAdded = false;
      let yPosition = this.position
      let validItems = data.filter(item => item.texto_info);

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Relatório por Especialidade");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;

      yPosition += lineHeight;
      doc.text("Relatório por Especialidade", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight;

      for (let i = 0; i < validItems.length; i++) {
        let item = validItems[i];
        let xPosition = 10;
        doc.setFontSize(12);

        if (yPosition >= 260) { // Se a evolução começar no fim da página, adiciona uma nova página
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, 260)
        }

        xPosition = 10;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        yPosition += lineHeight;

        doc.setFont("helvetica", "bold");
        doc.text(`Profissional:`, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Profissional: `);

        doc.setFont("helvetica", "normal");
        doc.text(`${item.apelido}.`, xPosition, yPosition);

        const profissionalWidth = doc.getTextWidth(`Profissional: ${item.apelido}. `);
        xPosition = xFixedPosition + profissionalWidth + lineWidth

        doc.setFont("helvetica", "bold");
        doc.text(`Conselho:`, xPosition, yPosition);
        xPosition += doc.getTextWidth(`Conselho:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.conselho}.`, xPosition, yPosition);

        const conselhoWidth = xPosition + doc.getTextWidth(`${item.conselho}. `);

        doc.setFont("helvetica", "bold");
        doc.text(`Nº Conselho: `, conselhoWidth, yPosition);
        xPosition = conselhoWidth + doc.getTextWidth(`Nº Conselho:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.nconselho}.`, xPosition, yPosition);


        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

        doc.setFont("helvetica", "bold");
        doc.text(`Procedimento: `, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Procedimento:`) + lineWidth;

        const nomeProcedimento = item.nomecomercial !== null ? `${item.procedimento} - (${item.nomecomercial})` : item.procedimento;
        doc.setFont("helvetica", "normal");
        doc.text(`${nomeProcedimento}.`, xPosition, yPosition);

        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

        doc.setFont("helvetica", "bold");
        doc.text(`Frequência: `, xFixedPosition, yPosition);
        xPosition = xFixedPosition + doc.getTextWidth(`Frequência:`) + lineWidth;

        doc.setFont("helvetica", "normal");
        doc.text(`${item.frequencia}.`, xPosition, yPosition);

        yPosition += lineHeight;
        yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

        if (item.uso_respirador !== "NÃO POSSUI" && item.uso_respirador !== "") {
          doc.setFont("helvetica", "bold");
          doc.text(`Usa Respirador de forma`, xFixedPosition, yPosition);
          xPosition = xFixedPosition + doc.getTextWidth(`Usa Respirador de forma:`);

          doc.setFont("helvetica", "normal");
          doc.text(`${item.uso_respirador === 'CONTÍNUO' ? 'contínua' : 'intermitente'}.`, xPosition, yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
        }

        const nonVariableParts = [
          "Relatório da Última Evolução: ",
          "Objetivo Esperado: ",
          "Informações para Prorrogação: ",
        ];
        const variableParts = [
          `${item.texto_evolucao}`,
          `${item.texto_meta}`,
          `${item.texto_info}`,
        ];

        let fullText = `Relatório da Última Evolução: ${item.texto_evolucao}. \nObjetivo Esperado: ${item.texto_meta}. \nInformações para Prorrogação: ${item.texto_info}`;
        let evolution = doc.getTextWidth(`Relatório da Última Evolução: : ${item.texto_evolucao}. Objetivo Esperado: ${item.texto_meta}. Informações para Prorrogação: ${item.texto_info}.`)
        let splittedText = doc.splitTextToSize(fullText, maxWidth);

        if (evolution < maxWidth) {
          doc.setFont("helvetica", "bold");
          doc.text("Relatório da Última Evolução: ", xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          doc.text(`${item.texto_evolucao}. `, xFixedPosition + lineWidth + doc.getTextWidth("Relatório da Última Evolução: "), yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

          doc.setFont("helvetica", "bold");
          doc.text("Objetivo Esperado: ", xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          doc.text(` ${item.texto_meta}.`, xFixedPosition + lineWidth + doc.getTextWidth("Objetivo Esperado: "), yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

          doc.setFont("helvetica", "bold");
          doc.text("Informações para Prorrogação: ", xFixedPosition, yPosition);
          doc.setFont("helvetica", "normal");
          doc.text(` ${item.texto_info}.`, xFixedPosition + lineWidth + doc.getTextWidth("Informações para Prorrogação: "), yPosition);

          yPosition += lineHeight;
          yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);


        } else {
          splittedText.forEach((line) => {
            yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);

            let currentX = xFixedPosition;

            nonVariableParts.forEach((part) => {
              if (line.includes(part)) {
                doc.setFontSize(12);
                doc.setFont("helvetica", "bold");
                doc.text(part, currentX, yPosition);
                currentX += doc.getTextWidth(part);
                line = line.replace(part, "");
              }
            });

            variableParts.forEach((part) => {
              if (line.includes(part)) {
                doc.setFontSize(12);
                doc.setFont("helvetica", "normal");
                doc.text(part, currentX, yPosition);
                currentX += doc.getTextWidth(part);
                line = line.replace(part, "");
              }
            });

            if (line.trim() !== "") {
              doc.setFontSize(12);
              doc.setFont("helvetica", "normal");
              doc.text(line, currentX, yPosition);
            }

            yPosition += lineHeight;
          })


          if (item.carimbo) {
            let carimbo = item?.carimbo
            let STAMP_URL = UtilsPDF.joinURL(`https://api.gssma.com.br/media/`, carimbo);

            if (yPosition + 20 > maxHeight) {
              doc.addPage();
              this.drawHeader(doc);
              yPosition = 86;
            }

            doc.setFont("helvetica", "bold");
            doc.text("Carimbo: ", 10, yPosition);
            doc.setFont("helvetica", "normal");
            yPosition += lineHeight;

            doc.addImage(
              STAMP_URL,
              "PNG" || "JPEG",
              10,
              yPosition,
              30,
              10
            );

            const imgHeight = 10;
            yPosition += imgHeight + lineHeight;
          } else {
            yPosition += lineHeight;
          }

          if (yPosition <= 230 || yPosition >= 140) {
            yPosition = this.checkAndAddPage(doc, yPosition, lineHeight, maxHeight);
          }

          if (i !== validItems.length - 1 && yPosition > 100 && yPosition < 250) {
            doc.line(10, yPosition, pageWidth - 10, yPosition);
            yPosition += 2;
            this.contentAdded = true;
          }
        }
        // Evita criar uma página em branco no final
        if (!this.contentAdded) {
          doc.deletePage(doc.internal.getNumberOfPages());
        }
      }
      // Exclui uma página em branco no fim do arquivo, se houver
      if (!this.contentAdded && doc.internal.getNumberOfPages() > 1) {
        doc.deletePage(doc.internal.getNumberOfPages());
      }
    },
    checkAndAddPage(doc, yPosition, lineHeight, maxHeight) {
      if (yPosition + lineHeight > maxHeight) {
        doc.addPage();
        this.drawHeader(doc);
        this.position = 80;
        return this.position;
      } else {
        this.contentAdded = true;
        return yPosition;
      }
    },
    checkAndAddBudgetPage(doc, yPosition, lineHeight, maxHeight) {
      if (yPosition + lineHeight > maxHeight) {
        doc.addPage();
        this.drawHeader(doc);  // Chama a função de desenho de cabeçalho passada como argumento
        return 80;  // Retorna o novo yPosition
      } else {
        this.contentAdded = true;
        return yPosition;  // Retorna o yPosition inalterado
      }
    },
    async getProrrogacao() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-prorrogacao/?orcamento=${this.selectedBudgets[0]}`);
          this.prorrogacao = data.filter(item => item.aditivo === false);
        } catch (error) {
          this.$toast.error("Erro ao carregar Prorrogação (getProrrogacao):", error);
        }
      }
    },
    async getOrcamento() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets[0]}`);
          this.orcamento = data
          this.pacienteID = this.orcamento[0].paciente_id
          this.getQuadroClinico()
        } catch (error) {
          this.$toast.error("Erro ao carregar Orçamento (getOrcamento):", error);
        }
      }
    },
    async getPacote() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/pacotes/?orcamento=${this.selectedBudgets[0]}`);
          this.pacote = data.filter(item => item.ativo);
          // console.log('Pacote --> ',this.pacote)
        } catch (error) {
          this.$toast.error("Erro ao carregar Pacote (getPacote):", error);
        }
      }
    },
    async getTaxa() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.selectedBudgets[0]}`);
          this.taxa = data.filter(item => item.ativo);
          // console.log('Taxas --> ', this.taxa)
        } catch (error) {
          this.$toast.error("Erro ao carregar  Taxa (getTaxa):", error);
        }
      }
    },
    async getEquipamentos() {
      if (this.selectedBudgets.length > 0) {
        try {
          const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedBudgets[0]}`);
          this.equipamentos = data.filter(item => item.ativo);
          // console.log('Equipamentos --> ', this.equipamentos)
        } catch (error) {
          this.$toast.error("Erro ao carregar Equipamentos(getEquipamentos):", error);
        }
      }
    },
    async getQuadroClinico() {
      if (this.pacienteID) {
        try {
          const { data } = await api.get(`atendimentos/app-texto-quadroclinico/?paciente=${this.pacienteID}`);
          this.quadroClinico = data[0]
          // console.log('getQuadroClinico -> ', this.quadroclinico)
        } catch (error) {
          this.$toast.error("Erro ao carregar os Quadro Clinico (getQuadroClinico):", error);
        }
      }
    },
  },
}
</script>